import axios from 'axios'

const API = 'landing/seccion/categoria'

const Categorias = {
    postLandingSeccion(payload){
        return axios.post(`${API}/guardar-seccion`,payload)
    },
    detalleLandingSeccion(params){
        return axios.get(`${API}/detalle-seccion`,{params})
    },
    selectCategorias(params){
        return axios.get(`${API}/listar-select`,{params})
    },
    getCategorias(params){
        return axios.get(`${API}/listar`,{params})
    },
    postCategoria(payload){
        return axios.post(`${API}/guardar`,payload)
    },
    deleteCategoria(params){
        return axios.delete(`${API}/eliminar`,{params})
    },
    actualizarSeccion(payload){
        return axios.put(`${API}/actualizar-seccion`,payload)
    },
    cambiarPosicion(payload){
        return axios.post(`${API}/cambiar-posicion`,payload)
    },

}

export default Categorias
